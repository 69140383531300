/** @jsx jsx */
import { css, jsx } from '@emotion/react';
import { graphql, Link } from 'gatsby';
import { getImage } from 'gatsby-plugin-image';
import { BgImage } from 'gbimage-bridge';
import Layout from '../components/Layout';
import NewsletterFormSidebar from '../components/NewsletterFormSidebar';
import SEO from '../components/seo/seo';

export default function AnnualReviews({ data }) {
  const image = getImage(data.heroBg);

  const imgStack = ['linear-gradient(rgba(0,0,0,0.4),rgba(0,0,0,0.7))', image];

  return (
    <Layout>
      <SEO />
      <BgImage
        css={css`
          &::after,
          &::before {
            background-position: 50% 10%;
            background-size: cover;
          }
        `}
        style={{ backgroundPosition: '', backgroundSize: '' }}
        image={imgStack}
      >
        <div>
          <div className="container py-12  md:pb-24 md:pt-24 ">
            <h1 className="text-gray-100 font-bold text-3xl md:text-5xl mt-0">Annual Reviews</h1>
          </div>
        </div>
      </BgImage>
      <div className="container pt-8 pb-12 font-body">
        <div className="flex flex-wrap">
          <div className="md:w-3/4 w-full md:border-r border-gray-300 md:pr-6">
            <p>
              <b>2019 Annual Review</b>
              <br />
              <strong>Read Time:</strong> Private — To be released at the end of 2020.
              <br />
              <strong>Themes:</strong> Transition, Travel, Mental Health, Community, Leadership,
              Family
            </p>
            <p>
              <Link to="/2018-annual-review">2018 Annual Review</Link>
              <br />
              <strong>Read Time:</strong> 25 mins
              <br />
              <strong>Themes:</strong> Community, Friends, Leadership, Communication, Service
            </p>

            <p>
              <Link to="/2017-annual-review">2017 Annual Review</Link>
              <br />
              <strong>Read Time:</strong> 35 mins
              <br />
              <strong>Themes:</strong> Explore, People, Decisions, Career, Distance, Touch
              <br />
            </p>

            <p>
              <Link to="/2016-annual-review">2016 Annual Review</Link>
              <br />
              <strong>Read Time:</strong> 23 mins
              <br />
              <strong>Themes:</strong> Community, Reading, Writing, Broken Habits, Depression,
              Burning Man
              <br />
            </p>

            <p>
              <Link to="/2015-annual-review">2015 Annual Review</Link>
              <br />
              <strong>Read Time:</strong> 14 mins
              <br />
              <strong>Themes:</strong> Habits, Experiences, Writing, Relationships, Ambition
              <br />
            </p>

            <p>
              <Link to="/2014-annual-review">2014 Annual Review</Link>
              <br />
              <strong>Read Time</strong>: 6 mins
              <br />
              <strong>Themes:</strong> Balance, Vulnerability, Consistency, and Focus.
              <br />
            </p>

            <p>
              <Link to="/2013-annual-review">2013 Annual Review</Link>
              <br />
              <strong>Read Time:</strong> 5 mins
              <br />
              <strong>Themes:</strong> New Beginnings, Foundation Building, Lost Connections
            </p>
          </div>

          <div className="md:w-1/4 w-full md:pl-6 ">
            <NewsletterFormSidebar />
          </div>
        </div>
      </div>
    </Layout>
  );
}

export const query = graphql`
  {
    heroBg: file(relativePath: { eq: "annual-reviews-bg.png" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH, formats: [AUTO, WEBP])
      }
    }
  }
`;
